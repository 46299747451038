import { Text } from '../text';

export interface InputLayoutProps {
	error?: string;
	description?: string;
	label?: string;
	children?: React.ReactNode;
	className?: string;
	remove_error_container?: boolean;
	inputDescription?: string;
}

export const InputLayout = ({
	description,
	error,
	label,
	className,
	children,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	remove_error_container,
	inputDescription,
}: InputLayoutProps) => {
	return (
		<div className={className}>
			{label && (
				<Text size="sm" className="mb-1">
					{label}
				</Text>
			)}
			{description && (
				<Text size="xs" className="mb-2">
					{description}
				</Text>
			)}
			{children}
			{inputDescription && (
				<Text size="xs" variant="secondary" className="mt-2">
					{inputDescription}
				</Text>
			)}
			{!remove_error_container && (
				<Text size="xs" variant="danger">
					{error || ' '}
				</Text>
			)}
		</div>
	);
};
