import type { TailwindStyleMapperType } from './layout.types';

export const LayoutStylePrefix: Record<keyof TailwindStyleMapperType, string> = {
	display: '',
	direction: 'flex-',
	gap: 'gap-',
	justify: 'justify-',
	align: 'items-',
	gridColumns: 'grid-cols-',
	colSpan: 'col-span-',
	gridRows: 'grid-rows-',
	rowSpan: 'row-span-',
};
