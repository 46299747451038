export const ERROR = {
	UNAUTHORIZED: 'You are not authorized to take this action',
	UNAUTHENTICATED: 'You need to be authenticated to take this action',
	NOT_FOUND: 'The resource you are looking for could not be found',
	GENERIC: 'Something went wrong',
	UNKNOWN: 'An unknown error occurred, please try again later',
	INVALID_PASSKEY: 'The passkey you used is not valid',
	AUTH_EXPIRED: 'Your session has expired, please login again',
	REFRESH_INTEGRATION: 'We have encountered an error while refreshing the integration, please try again later.',
	VALIDATION_ERROR: 'There was an error in the data you provided.',
} as const;
