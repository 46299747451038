export const TEST_IDS = {
	conversations: {
		listItem: 'convo-list-item',
		listItemTitle: 'convo-list-title', //Contact Name in list item
		message: 'convo-message',
		messageTime: 'convo-message-time',
		contactName: 'convo-contact', //Contact Name in convo header
		cartItem: 'convo-cart-item',

		//tabs
		dashboardTab: 'convo-dashboard-tab',
		listsTab: 'convo-lists-tab',
		feedbackTab: 'convo-feedback-tab',
	},
	kb: {
		listItem: 'kb-list-item',
		kbNewButton: 'kb-new-btn',
		listItemTitle: 'kb-list-title', //Contact Name in list item
		titleInput: 'kb-title-input',
		answerInput: 'kb-answer-input',
		kbSubmitButton: 'kb-submit-btn',
		kbStatusButton: 'kb-status-btn',
	},
	workflow: {
		cardItem: 'wf-card-item',
		itemName: 'wf-name',
		toggleState: 'wf-toggle-state',
	},
} as const;
