import type React from 'react';

import type { DatabaseEnum } from '@voyage-lab/db';
import type { IconWrapper } from '@voyage-lab/ui';

export const CheckoutTypeToDetails: Record<
	DatabaseEnum['t_checkout_state'],
	Pick<React.ComponentProps<typeof IconWrapper>, 'name' | 'color' | 'backgroundColor'> & {
		title: string;
		description?: string;
	}
> = {
	initial: {
		name: 'AnnotationDots',
		color: 'blue',
		backgroundColor: 'blue-100',
		title: 'Initial',
		description: 'Checkout was created',
	},
	message_queued: {
		name: 'DotsHorizontal',
		color: 'orange',
		backgroundColor: 'orange-100',
		title: 'Message Queued',
		description: 'Message was queued',
	},
	messaged: {
		name: 'AnnotationCheck',
		color: 'green',
		backgroundColor: 'green-100',
		title: 'Messaged',
		description: 'Customer was messaged',
	},
	abandoned: {
		name: 'CreditCardX',
		color: 'red',
		backgroundColor: 'red-100',
		title: 'Abandoned',
		description: 'Customer abandoned the checkout',
	},
	discount_sent: {
		name: 'BadgeDollarSign',
		color: 'yellow',
		backgroundColor: 'yellow-100',
		title: 'Discount Sent',
		description: 'Discount was sent',
	},
	recovered: {
		name: 'CheckCircleBroken',
		color: 'green',
		backgroundColor: 'green-100',
		title: 'Recovered',
		description: 'Customer recovered the checkout',
	},
	skipped: {
		name: 'MousePointerClick',
		color: 'blue',
		backgroundColor: 'blue-100',
		title: 'Skipped',
		description: 'Customer skipped the checkout',
	},
	errored: {
		name: 'CreditCardX',
		color: 'red',
		backgroundColor: 'red-100',
		title: 'Errored',
		description: 'Checkout errored',
	},
	abandoned_processing: {
		name: 'CreditCardX',
		color: 'red',
		backgroundColor: 'red-100',
		title: 'Abandoned Processing',
		description: 'Checkout was abandoned due to processing',
	},
	filtered: {
		name: 'Filter',
		color: 'blue',
		backgroundColor: 'blue-100',
		title: 'Filtered',
		description: 'Checkout was filtered',
	},
	no_identity: {
		name: 'AlertTriangle',
		color: 'blue',
		backgroundColor: 'blue-100',
		title: 'No Identity',
		description: 'Customer has no identity',
	},
};
