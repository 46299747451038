import type { SpacerProps } from './spacer.types';

const Spacer = ({ vertical, horizontal }: SpacerProps) => {
	return (
		<div
			style={{
				height: vertical,
				width: horizontal,
			}}
		/>
	);
};

export { Spacer };
